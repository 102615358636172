<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-17 15:30:56
 * @LastEditors: zhuxin xu
 * @LastEditTime: 2022-12-29 18:19:34
-->
<template>
  <div class="home">
    <div class="art-list">
      <TypeGroup v-for="item in data" :key="item.id" :data="item"></TypeGroup>
    </div>
  </div>
</template>

<script>
import TypeGroup from "./module/TypeGroup.vue";

export default {
  name: "Home",
  components: { TypeGroup },
  data() {
    return {
      expand: false,
      data: [],
    };
  },
  computed: {
    language() {
      return this.$store.state.defaultlanguage;
    },
    usageManageId() {
      return this.$store.state.wikiAppId;
    },
  },
  watch: {
    language() {
      this.usageCategoryList();
    },
  },
  mounted() {
    this.usageCategoryList();
  },
  methods: {
    usageCategoryList() {
      let params = {
        usageManageId: this.usageManageId,
        language: this.language,
      };
      this.$api.usageCategoryList(params).then((res) => {
        if (res.code == 200) {
          console.log(res.data);
          this.data = res.data.filter((item) => item.articleCount !== 0);
        } else {
          this.$message({
            type: "error",
            message: res.msg,
            duration: 1000,
            showClose: true,
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.art-list {
  max-width: 1200px;
  padding-left: 40px;
  padding-right: 40px;
  margin: 30px auto;
  min-height: 59vh;
}
</style>
