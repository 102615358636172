<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-17 20:46:12
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-02-03 11:59:49
-->
<template>
  <div class="group flex-center">
    <div class="icon">
      <img :src="data.usageCategoryIcon" alt srcset />
    </div>
    <div class="content">
      <div class="name">{{ data.usageCategoryName }}</div>
      <div class="remark">{{ data.usageCategoryDescribe }}</div>
      <div class="art-list">
        <div class="art-item" v-for="(item, index) in data.articles" @click.stop="handleToArt(item)" :key="index">
          <span class="dot"></span>
          <span>{{ item.articleName || $t("Detail.noName") }}</span>
        </div>
      </div>
      <div class="art-num" @click="enterCorrespondpage(data.id, data.articleCount)">
        {{ $t("Home.total") + data.articleCount + $t("Home.nums") + " , " + $t("Home.more") }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Group",
  props: {
    data: {
      default: () => {
        return {};
      },
    },
  },
  computed: {
    usageStyleId: function () {
      return this.$store.state.globalConfig.usageStyleId;
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    enterCorrespondpage(id) {
      if (this.usageStyleId == 1) {
        this.$router.push({
          name: "Collections",
          params: this.$route.params,
          query: { id },
        });
      } else {
        if (this.data.articles && this.data.articles.length > 0) {
          let articleId = this.data.articles[0].articleId;

          this.$router.push({
            name: "Detail",
            params: this.$route.params,
            query: {
              articleId,
              usageCategoryId: this.data.usageCategoryId,
            },
          });
        } else {
          this.$message.warning("该分类下未关联文章");
        }
      }
    },
    handleToArt({ articleId }) {
      this.$router.push({
        name: "Detail",
        params: this.$route.params,
        query: {
          articleId,
          usageCategoryId: this.data.usageCategoryId,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.group {
  background: #ffffff;
  box-shadow: 0 2px 24px 0 rgba(0, 77, 193, 0.08);
  border-radius: 8px;
  padding: 24px;
  margin: 24px 0;

  .icon {
    width: 34px;
    height: 34px;
    padding: 33px;
  }
  .content {
    margin-left: 24px;
    width: 100%;
    .name {
      font-family: PingFangSC-Medium;
      font-size: 19px;
      color: #000000;
      font-weight: 500;
      letter-spacing: -0.28px;
      font-weight: 500;
      margin-bottom: 8px;
    }
    .remark {
      font-family: PingFangSC-Regular;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.4);
      letter-spacing: 0;
      line-height: 20px;
      font-weight: 400;
    }
    .art-list {
      width: 100%;
      margin: 16px 0;
      .art-item {
        cursor: pointer;
        padding: 5px 0;
        font-family: PingFangSC-Regular;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.8);
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 400;
        display: flex;
        align-items: center;
        .dot {
          display: inline-block;
          background-color: rgba(0, 0, 0, 0.8);
          min-width: 5px;
          height: 5px;
          border-radius: 50%;
          margin-left: 4px;
          margin-right: 12px;
        }
        &:hover {
          color: var(--theme-color);
          text-decoration: underline;
        }
      }
    }
    .art-num {
      cursor: pointer;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.4);
      letter-spacing: -0.17px;
      font-weight: 400;
    }
  }
}
</style>
